import React from 'react';
import './styling/ProdDropdown.scss';

const ProdDropdown = () => {
  return (
    <div className="dropdown">
      <ul className="menu">


        {/* <li>
          <ul className='dropdownContent'>
            <li>
              <a href="/products/interior">
                <h2>Interior</h2>
              </a>
            </li>
            <li><a href="/products/interior/acrylic-signs">Acrylic Signs</a></li>
            <li><a href="/products/interior/banner-stands">Banner Stands</a></li>
            <li><a href="/products/interior/canvas-signs">Canvas Signs</a></li>
            <li><a href="/products/interior/corrugated-signs">Corrugated Signs</a></li>
            <li><a href="/products/interior/foam-boards">Foam Boards</a></li>
            <li><a href="/products/interior/metal-signs">Metal Signs</a></li>
            <li><a href="/products/interior/office-signs">Office Signs</a></li>
            <li><a href="/products/interior/plastic-signs">Plastic Signs</a></li>
            <li><a href="/products/interior/posters">Posters</a></li>
            <li><a href="/products/interior/window-decal">Wall Decal</a></li>
          </ul>
        </li> */}
        {/* <li>
          <ul className='dropdownContent'>
            <li>
              <a href="/products/exterior">
                <h2>Exterior</h2>
              </a>
            </li>
            <li><a href="/products/exterior/acrylic-signs">Acrylic Signs</a></li>
            <li><a href="/products/exterior/banner-flags">Banner Flags</a></li>
            <li><a href="/products/exterior/corrugated-signs">Corrugated Signs</a></li>
            <li><a href="/products/exterior/custom-yard-signs">Custom Yard Signs</a></li>
            <li><a href="/products/exterior/display-signs">Display Signs</a></li>
            <li><a href="/products/exterior/foam-boards">Foam Boards</a></li>
            <li><a href="/products/exterior/metal-signs">Metal Signs</a></li>
            <li><a href="/products/exterior/pvc-board-signs">PVC Board Signs</a></li>
            <li><a href="/products/exterior/real-estate-signs">Real Estate Signs</a></li>
            <li><a href="/products/exterior/window-decal">Window Decal</a></li>
            <li><a href="/products/exterior/yard-signs">Yard Signs</a></li>
          </ul>
        </li> */}
        {/* <li>
          <ul className='dropdownContent'>
            <li>
              <a href="/products/printing">
                <h2>Printing</h2>
              </a>
            </li>
            <li><a href="/products/printing/brochures">Brochures</a></li>
            <li><a href="/products/printing/buiness-cards">Business Cards</a></li>
            <li><a href="/products/printing/door-hangers">Door Hangers</a></li>
            <li><a href="/products/printing/engineering-draft-plans">Engineering Draft Plans</a></li>
            <li><a href="/products/printing/flyers">Flyers</a></li>
            <li><a href="/products/printing/high-quality-prints">High Quality Prints</a></li>
            <li><a href="/products/printing/menus">Menus</a></li>
            <li><a href="/products/printing/ncr-forms">NCR Forms</a></li>
            <li><a href="/products/printing/notepads">Notepads</a></li>
            <li><a href="/products/printing/postcards">Postcards</a></li>
            <li><a href="/products/printing/posters">Posters</a></li>
          </ul>
        </li> */}
        {/* <li>
          <ul className='dropdownContent'>
            <li>
              <a href="/products/banners">
                <h2>Banners</h2>
              </a>
            </li>
            <li><a href="/products/banners/advertising-banners">Advertising Banners</a></li>
            <li><a href="/products/banners/banner-stands">Banner Stands</a></li>
            <li><a href="/products/banners/event-banners">Event Banners</a></li>
            <li><a href="/products/banners/pop-up-banners">Pop-up Banners</a></li>
            <li><a href="/products/banners/promotional-banner-displays">Promotional Banner Displays</a></li>
            <li><a href="/products/banners/vinyl-banners">Vinyl Banners</a></li>
          </ul>
        </li> */}
        {/* <li>
          <ul className='dropdownContent'>
            <li>
              <a href="/products/signs">
                <h2>Signs</h2>
              </a>
            </li>
            <li><a href="/products/signs/coroplast-signs">Coroplast Signs</a></li>
            <li><a href="/products/signs/foam-board-signs">Foam Board Signs</a></li>
            <li><a href="/products/signs/outdoor-signs">Outdoor Signs</a></li>
            <li><a href="/products/signs/real-estate-yard-signs">Real Estate Yard Signs</a></li>
            <li><a href="/products/signs/retail-signs">Retail Signs</a></li>
            <li><a href="/products/signs/trade-show-signs">Trade Show Signs</a></li>
            <li><a href="/products/signs/yard-signs">Yard Signs</a></li>
          </ul>
        </li> */}
      </ul>
    </div>

  );
};

export default ProdDropdown;