import React from 'react';
import './styling/HeroSection.scss';
import { ReactComponent as RightArrow } from '../assets/images/rightArrow.svg'

const HeroSection = () => {
  return (
    <section className="hero-block">
      <div className="heroSection">
        <div className="heroContent">
          <h1>Print and Sign Solutions in One Click</h1>
          <p>Check out our professional printing and sign products</p>
          <a href="/products">
            <p>Browse Our Products</p>
            <div className='arrowWrapper'>
              <RightArrow className="rightArrow" />
            </div>
          </a>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
