import React from 'react';
import './styling/Footer.scss';
import { ReactComponent as LocationSvg } from '../assets/images/location-icon.svg';
import { ReactComponent as PhoneSvg } from '../assets/images/phone-icon.svg';
import { ReactComponent as MailSvg } from '../assets/images/mail-icon.svg';
import Logo from '../assets/images/logo-cropped.png';

const Footer = ({className}) => {
  console.log(className);
  return (
    <footer name="footer-universal" className={className}>
      <div className="footerWrapper">
        <div className="footer">
          <div className="imgWrapper">
            <a href="/"><img src={Logo} alt="BK Printing Co Logo"/></a>
          </div>
          <div className="footerContent">
            <div className='links'>
              <div>
                <div className='footerTitle'>Pages</div>
                <ul className="siteLinks">
                  <li><a href="/">Home</a></li>
                  <li><a href="/about">About Us</a></li>
                  <li><a href="/products">Products</a></li>
                  <li><a href="/contact">Contact Us</a></li>
                  <li><a href="/login/">Login</a></li>
                </ul>
              </div>
            </div>
            <div className="copyright">
              <p>&copy; 2023 BK Printing Co. All Rights Reserved.</p>
            </div>
            <div className="contactInfo">
              <a href="tel://832-782-3688">
                <div className='svgWrapper'>
                  <PhoneSvg />
                </div>
                <p>832-782-3688</p>
              </a>
              <a href="mailto: bkhwy6@gmail.com">
                <div className='svgWrapper'>
                  <MailSvg />
                </div>
                <p>bkhwy6@gmail.com</p>
              </a>
              <a className='locLink' href="https://goo.gl/maps/Ye8gCCPghBrGFiVa9">
                <div className='svgWrapper'>
                  <LocationSvg />
                </div>
                <p>6601 W Sam Houston Pkwy S<br />Suite #4<br />Houston, TX 77072</p>
              </a>
            </div>
            <div className='legal'>
              <a href="/privacy-policy">Privacy Policy</a>
              <div className="vertDivider"></div>
              <a href="/terms-of-use">Terms of Use</a>
              <div className="vertDivider"></div>
              <a href="/accessibility">Accessibility</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
