import Header from '../component/Header';
import Footer from '../component/Footer';
import React from 'react';
import './styling/CurrentlyBuilding.scss'


const CurrentlyBuilding = () => {
    return (
        <>
        <Header/>
        <div className='buildingPageWrapper'>
            <div className='mainBody'>
            <h1> Howdy! </h1>
                <div className='pBody'>
                    <p> We're currently working this page. 🛠️ </p>
                    <p> Please check back soon as we continue work on the site! ⚙️ </p>
                    <p> Thanks! </p>
                </div>
                <hr color="#f2f2f2" width="50%" size="1"></hr>
                <div className='moreInfo'>
                    <p> You can reach us at the following: </p>
                    <p>Mobile: 832-782-3688</p>
                    <p>Email: bkhwy6@gmail.com</p>
                </div>
            </div>
        </div>
        <Footer />
        </>
    );
};



export default CurrentlyBuilding;