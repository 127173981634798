import {React, useState} from "react";
import './styling/ContactsForm.scss'


function ContactsForm (){

    const [inputs, setInputs] = useState({});

    const handleChange = (event) =>{
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]:value}))

    }

    const handleSubmit = (event) => {
      event.preventDefault();
      console.log(inputs);
    }

    return(
        <section className="contactsForm">
            <div className="titleWrapper">
                <h1 className="titleContact">
                    Contact Us
                </h1>
                <div className="highlightLine"></div>
            </div>
            <div className="contactsFormWrapper">
                <div className="sideContent"></div>
                <div className="contactsForm">
                    <form onSubmit={handleSubmit}>
                        <input type="text" placeholder='Full Name' size="30" value={inputs.fullname || ""} onChange={handleChange} name="fullname"/>
                        <input type="text" placeholder='Email' value={inputs.email || ""} onChange={handleChange} name="email"/>
                        <input type="text" placeholder='Phone Number' value={inputs.phone || ""} onChange={handleChange} name="phone"/>
                        <div className="messageBody">
                          <h5>Message</h5>
                         <input type="text" value={inputs.body || ""} onChange={handleChange} name="body"/>
                        </div>
                        <input type="submit" />
                    </form>
                </div>
            </div>
        </section>
    )
}

export default ContactsForm;