import React from 'react';
import { Routes, Route } from "react-router-dom"
import './App.scss';
import Home from '../../pages/Home'
import ContactsPage from '../../pages/ContactsPage'
import AboutPage from '../../pages/AboutPage'
import ProductPage from '../../pages/ProductPage'
import NotFoundPage from '../../pages/NotFoundPage';
import ProductsListPage from '../../pages/ProductsListPage';
import CurrentlyBuilding from '../../pages/CurrentlyBuilding'


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={ <Home/> } />
        <Route path="/contact" element={ <CurrentlyBuilding />} />
        <Route path="/about" element={ <AboutPage />} />
        <Route path="/products" element={ <CurrentlyBuilding />} />
        <Route path= "/products/:productGroup/:productId" element={ <ProductPage />} />
        <Route path= "/products/*" />
        <Route path="*" element={ <NotFoundPage /> } />
      </Routes>
    </div>
  )
}
export default App  