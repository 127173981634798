import React from 'react';
import './styling/Home.scss';
import Header from '../component/Header';
import HeroBanner from '../component/HeroSection'
import TopProductsBlock from '../component/TopProductsBlock';
import CraftBlock from '../component/CraftBlock';
import WhyChooseUs from '../component/WhyChooseUs';
import GetQuoteBlock from '../component/GetQuoteBlock';
import Footer from '../component/Footer';


const Home = () => {
  return (
    <div className="Home">
      <Header/>
      <HeroBanner/>
      <TopProductsBlock/>
      <CraftBlock/>
      {/* <WhyChooseUs/> */}
      <GetQuoteBlock/>
      <Footer/>
    </div>
  );
};

export default Home;
