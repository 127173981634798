import Header from '../component/Header';
import Footer from '../component/Footer';
import '../pages/styling/AboutPage.scss'

const AboutPage = () => {
    return (
      <div className='pageWrapper'>
        <Header/>
        <div name="hero-description-section">
            <div className="aboutPageWrapper">
              <div className='sectionHeroDescription'>
                <h1>About Us</h1>
                <p className='hero-section-short-blurb'>
                  We simplify printing and advertising <br></br>
                  <strong>
                  for companies in Houston.
                  </strong>
                </p> 
              </div>
  
              <section className="section-highlights">
                <div className='sectionHighlights'>
                  <p name='section-highlight-description'>
                    BK Printing Co. serves the Greater Houston area <br></br> with 
                    wide varieties of printing and sign products <br></br> and services. 
                    We deliver professional work with high functionality and customization.
                  </p>
                  <div className='statsHighlightWrapper'>
                    <div className='statHighlight'>
                      <div className='stat-highlight-title'>
                        1997
                      </div>
                      <div className='stat-highlight-blurb'>
                        BK Printing Co. launched
                      </div>
                    </div>
                    <div className='statHighlight'>
                      <div className='stat-highlight-title'>
                        100+
                      </div>
                      <div className='stat-highlight-blurb'>
                        printing + sign products
                      </div>
                      
                    </div>
                    <div className='statHighlight'>
                      <div className='stat-highlight-title'>
                        100K
                      </div>
                      <div className='stat-highlight-blurb'>
                        satisfied customers
                      </div>
                      
                    </div>
                  </div>
                </div>
              </section>
  
              <section className='section-our-story'>
                <div className="historyStory">
                  <h1>
                    Our story.
                  </h1>
                  <p>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. 
                  Vulputate maecenas nostra porttitor quisque odio; montes 
                  senectus tellus. Rutrum ridiculus arcu viverra ipsum sociosqu. 
                  Class senectus congue magna porttitor euismod nec nisl quisque 
                  libero? Condimentum proin iaculis turpis penatibus pulvinar senectus 
                  class nostra. Ligula a fames ligula justo vehicula vulputate; consequat ornare.
                  <br/>
                  </p>
                  <p>
                  Ligula nostra sociosqu imperdiet eu curabitur et ultrices
                  pretium penatibus. Hendrerit vestibulum ad eros molestie
                  eleifend consectetur et nam sed. Morbi tincidunt iaculis
                  suscipit libero platea ex in. Dis turpis donec volutpat
                  phasellus malesuada. Ornare vestibulum diam lacus efficitur
                  proin primis facilisi. Est ipsum lobortis ac velit non eu ac.
                  Nunc ultrices curabitur id aenean vulputate maximus
                  interdum aliquet. At turpis neque fusce nam nullam
                  iaculis etiam justo. Facilisi phasellus egestas nisl
                  ipsum congue.
                  <br/>
                  </p>
                </div>
              </section>
  
            <section className='section-our-team'>
              <div className='ourTeam'>
                <h1>Our Team</h1>
                <p>
                Lorem ipsum odor amet, consectetuer adipiscing elit. 
                Vulputate maecenas nostra porttitor quisque odio; montes 
                senectus tellus. Rutrum ridiculus arcu viverra ipsum sociosqu. 
                Class senectus congue magna porttitor euismod nec nisl quisque 
                libero? Condimentum proin iaculis turpis penatibus pulvinar senectus 
                class nostra. Ligula a fames ligula justo vehicula vulputate; consequat ornare.
                </p>
              </div>
            </section>
  
            {/* <section className='section-work-with-us'>
              <div>
                something about working together on stuff?
              </div>
  
            </section> */}
        </div>
            </div>
        <Footer />
      </div>
    );
  };
  
  export default AboutPage;
  