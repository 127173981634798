import React from 'react';
import './styling/CraftBlock.scss';
import Wheel from '../assets/images/wheel.png';


const CraftBlock = () => {
  return (
    <>
      <hr color="#f2f2f2" width="50%" size="1"></hr>
      <section className='craft-block'>
        <div className="craftBanner">
          <div className="craftContent">
            <h1>Professional Solutions. <br></br>One Click.</h1>
            <p>We strive for perfection with every order. 
              With over 25 years of expertise, and knowledge, 
              expect only the best.</p>
          </div>
          {/* <img src={Wheel} alt="wheel"/> */}
        </div>
      </section>
    </>
  );
};

export default CraftBlock;
