import React from "react";
import './styling/ProductsBlock.scss'
import productData from "../assets/products/productData";

const TopProductsBlock = () => {
    
    let serviceList = [
        productData["banners"]["vinyl-banners"],
        productData["printing"]["posters"],
        productData["printing"]["business-cards"]
    ];

    return (
        <section className="productListing">
            <hr color="#f2f2f2" width="50%" size="1"></hr>
            <div className='productsBlock'>
                <div className="titleWrapper">
                    <h1>Product Highlights</h1>
                </div>
                <div className="productHighlightDescriptions">
                    <p>
                        With over 30 years of experience, we provide fast and 
                        professional custom sign, decal, and design printing 
                        services to help your business stand out.
                    </p>
                </div>
                <ul>
                    {
                        serviceList.map((product) => (
                            <li>
                                <a href={product.ancestors[product.ancestors.length - 1].path}>
                                    <div className="tempBox">
                                        { product.image ? 
                                        <img src={product.image} alt={product.productImageAlt}></img>
                                        : null}
                                    </div>
                                    <p>{product.name}</p>
                                </a>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </section>
    )
};

export default TopProductsBlock;
