import React from 'react';
import './styling/GetQuoteBlock.scss';
import { ReactComponent as Asterisk } from '../assets/images/big-asterisk.svg'

const GetQuoteBlock = () => {
  return (
    <section className="get-quote-block">
      <div className="getQuoteBanner">
        <Asterisk className="asterisk" />
        <div className="getQuoteContent">
          <h2>Get a Quote Today</h2>
          <p>Unique printings leave a lasting impact because a great first impression drives meaningful engagement.</p>
          <a href="/contact">Connect with Us</a>
        </div>
      </div>
    </section>
  );
};

export default GetQuoteBlock;
