import React from 'react';
import './styling/Header.scss';
import HeaderMobile from './HeaderMobile';
import ProdDropdown from './ProdDropdown';
import Logo from '../assets/images/logo-cropped.png';

const Header = () => {
  return (

      <header>
        <nav className="header">
          <div className="headerContent">
            <div className="imgWrapper">
              <a href="/"><img src={Logo} alt="BK Printing Co Logo"/></a>
            </div>
            <ul className="flexItem siteLinks">
              <li><a href="/"><span>Home</span></a></li>
              <li><a href="/about"><span>About Us</span></a></li>
              {/* <li><a href="/services"><span>Services</span></a></li> */}
              <li id='productsHeader'>
                <a href="/products"><span>Products <i className="downArrow"></i></span></a>
                <ProdDropdown />
              </li>
              {/* <li>< a href="/login">Login</a></li> */}
              <li><a href="/contact"><span>Contact</span></a></li>
            </ul>
          </div>
          <div className='thinLine'></div>
        </nav>
        <HeaderMobile />
      </header>

  );
};

export default Header;