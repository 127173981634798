import vinylBanner from './vinyl-banner/18131347.jpg';
import businessCards from './business-card-mockup/115_business_card.jpg';
import doorHanger from './doorHanger-mockup/7636.jpg';
import brochure from './brochure-mockup/3910925.jpg';
import postcard from './postcard-mockup/736.jpg';
import poster from './poster-mockup/7929535.jpg';

let vinylBannerData = {
    "name": "Vinyl Banner",
    "image": vinylBanner,
    "description": "Vinyl banners are a great way to promote your business or event. We offer a variety of sizes and materials to fit your needs.",
    "price": 100.00,
    "options": {
        "size": ["2x4", "3x6", "4x8"],
        "material": ["13oz Vinyl", "15oz Vinyl", "Mesh"]
    },
    "ancestors": [
      {
          "name": "Homepage",
          "path": "/"
      },
      {
          "name": "Products",
          "path": "/products"
      },
      {
          "name": "Banners",
          "path": "/products/banners"
      },
      {
          "name": "Vinyl Banners",
          "path": "/products/banners/vinyl-banners"
      }
    ]
}
let businessCardData = {
    "name": "Business Cards",
    "image": businessCards,
    "description": "Business cards are a great way to promote your business or event. We offer a variety of sizes and materials to fit your needs.",
    "price": 100.00,
    "options": {
        "size": ["2x4", "3x6", "4x8"],
        "material": ["13oz Vinyl", "15oz Vinyl", "Mesh"]
    },
    "ancestors": [
      {
          "name": "Homepage",
          "path": "/"
      },
      {
          "name": "Products",
          "path": "/products"
      },
      {
          "name": "Printing",
          "path": "/products/printing"
      },
      {
          "name": "Business Cards",
          "path": "/products/printing/business-cards"
      }
    ]
}
let doorHangerData = {
    "name": "Door Hangers",
    "description": "Door hangers are great for advertising your business or campaign.",
    "image": doorHanger,
    "price": 100.00,
    "options": {
        "size": ["2x4", "3x6", "4x8"],
        "material": ["13oz Vinyl", "15oz Vinyl", "Mesh"]
    },
      "ancestors": [
          {
              "name": "Homepage",
              "path": "/"
          },
          {
              "name": "Products",
              "path": "/products"
          },
          {
              "name": "Printing",
              "path": "/products/printing"
          },
          {
              "name": "Door Hangers",
              "path": "/products/printing/door-hangers"
          }
      ]
}
let brochureData = {
      "name": "Brochures",
      "description": "Brochures are great for advertising your business or campaign.",
      "image": brochure,
      "price": 100.00,
      "options": {
          "size": ["2x4", "3x6", "4x8"],
          "material": ["13oz Vinyl", "15oz Vinyl", "Mesh"]
      },
        "ancestors": [
            {
                "name": "Homepage",
                "path": "/"
            },
            {
                "name": "Products",
                "path": "/products"
            },
            {
                "name": "Printing",
                "path": "/products/printing"
            },
            {
                "name": "Brochures",
                "path": "/products/printing/brochures"
            }
        ]
  }
let postcardData = {
    "name": "Postcards",
    "description": "Postcards are a great way to keep in touch with your community.",
    "image": postcard,
    "price": 100.00,
    "options": {
        "size": ["2x4", "3x6", "4x8"],
        "material": ["13oz Vinyl", "15oz Vinyl", "Mesh"]
    },
      "ancestors": [
          {
              "name": "Homepage",
              "path": "/"
          },
          {
              "name": "Products",
              "path": "/products"
          },
          {
              "name": "Printing",
              "path": "/products/printing"
          },
          {
              "name": "Postcards",
              "path": "/products/printing/postcards"
          }
      ]
}
let posterData = {
    "name": "Posters",
    "description": "Posters are a great way to make your brand pop. Display metrics, promotions, or just a cool design.",
    "image": poster,
    "price": 100.00,
    "options": {
        "size": ["2x4", "3x6", "4x8"],
        "material": ["13oz Vinyl", "15oz Vinyl", "Mesh"]
    },
      "ancestors": [
          {
              "name": "Homepage",
              "path": "/"
          },
          {
              "name": "Products",
              "path": "/products"
          },
          {
              "name": "Printing",
              "path": "/products/printing"
          },
          {
              "name": "Posters",
              "path": "/products/printing/posters"
          }
      ]
}


let productData = {
    "interior": {},
    "exterior": {},
    "banners": {
        "vinyl-banners": vinylBannerData
    },
    "printing": {
        "business-cards": businessCardData,
        "door-hangers": doorHangerData,
        "brochures": brochureData,
        "postcards": postcardData,
        "posters": posterData
    },
    "signs": {}
}

export default productData;