import { useParams } from 'react-router-dom';
import './styling/ProductPage.scss';
import Header from '../component/Header';
import Footer from '../component/Footer';
import productData from '../assets/products/productData';
import NotFoundPage from './NotFoundPage';

const ProductPage = () => {
  const { productGroup, productId } = useParams();
  const productGroupData = productData[productGroup] ? productData[productGroup] : null;
  const product = (productGroupData && productGroupData[productId]) ? productGroupData[productId] : null;

  return ( product ? (
    <div className="Home">
      <Header />
      <div className="product-block">
        <div className='breadcrumb'>
          {product.ancestors.map((ancestor, i, {length}) => (
            i === length - 1 ?
              ( <span key={ancestor.name}>
                  <a href={ancestor.path}>{ancestor.name}</a>
                </span>)
            :
              (
                <span key={ancestor.name}>
                  <a href={ancestor.path}>{ancestor.name}</a>{" > "}
                </span>
              )
          ))}
        </div>
        <div className="product-image">
          <img src={product.image} alt={product.name} />
        </div>
        <div className="product-details">
          <div>
            <div className="title">{product.name}</div>
            <p className="product-description">{product.description}</p>
            <p className="product-price">Est: ${product.price.toFixed(2)}</p>
          </div>
          <div className="product-options">
            <label htmlFor="size-select">Size:
              <select
                name='size'
                id="size-select"
                // value={product.options.size[0]}
                // onChange={updateSize}
              >
                {product.options.size.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </label>

            <label htmlFor="material-select">Material:
              <select
                name='material'
                id="material-select"
                // value={product.options.material[0]}
                // onChange={updateMaterial}
              >
                {product.options.material.map((material) => (
                  <option key={material} value={material}>
                    {material}
                  </option>
                ))}
              </select>
            </label>
          </div>


          <button className="add-to-cart">Get Quote</button>
        </div>
      </div>
      <Footer />
    </div>
  ) : (
    <NotFoundPage />
  )
)
};

export default ProductPage;
