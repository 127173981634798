import React, { useState } from 'react'
import './styling/Header.scss';
import Logo from '../assets/images/logo-cropped.png';

const HeaderMobile = () => {
  const [isActive, setActive] = useState("true");

  const ToggleNav = () => {
    setActive(!isActive); 
    console.log(isActive);
   };

  return (
    <header className="mobileHeader">
        <div className={isActive ? "headerContent" : "headerContent  isActive"}>
          <div className={isActive ? "imgWrapper" : "imgWrapper whoosh"}>
            <a href="/"><img src={Logo} alt="BK Printing Co Logo"/></a>
          </div>
          <div className="burgerWrapper">
            <div className={isActive ? "hamburgerMenu" : "hamburgerMenu clicked"} onClick={ToggleNav}>
              <div id="a-burger" className="hamburgerLine"></div>
              <div id="b-burger" className="hamburgerLine"></div>
              <div id="c-burger" className="hamburgerLine"></div>
            </div>
          </div>
      </div>
      <ul className={isActive ? "mobileSiteLinks" : "mobileSiteLinks show"}>
        <li><a href="/">Home</a></li>
        <li><a href="/about">About</a></li>
        <li><a href="/products">Products</a></li>
        <li><a href="/contact">Contact</a></li>
      </ul>
    </header>
  );
};

export default HeaderMobile;